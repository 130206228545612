import React, {useState, useEffect, useMemo, useCallback, Suspense} from "react";
import * as S from '../../../styles/core-styles/AdminTools-styles';
import AdminTableView from "./admin-components/AdminTableView";
import UpdateLocationModal from "./UpdateLocationModal";
import CreateLocationGroupModal from "./CreateLocationGroupModal";
import { _boolSort } from "./admin-components/TableHelpers";
import Loading from "./swt-loading";

const LocationEditMap = React.lazy(() => import('./LocationEditMap'))

const ANALYTICS_IN_PROCESS_MESSAGE = "Analytics is already reprocessing data.";
const SERVER_ERRORED_MESSAGE = "There was a server error during your request.";

export default function LocationEditTool(props) {
    const {user, apiURL, db} = props;

    const [locationToUpdate, setLocationToUpdate] = useState();
    const [ungroupedLocations, setUngroupedLocations] = useState([]);
    const [selectedLocationsUuids, setSelectedLocationsUuids] = useState([]);
    const [selectedLocationsIds, setSelectedLocationsIds] = useState([]);
    const [selectedLocationsList, setSelectedLocationsList] = useState([]);
    //Group locations
    const [groupLocations, setGroupLocations] = useState([]);
    const [selectedGroupLocation, setSelectedGroupLocation] = useState([]);
    //Modal display control
    const [showUpdateLocationModal, setShowUpdateLocationModal] = useState(false);
    const [showCreateGroupLocationModal, setShowCreateGroupLocationModal] = useState(false);

    const [errorList, setErrorList] = useState([]);
    const [allLocations, setAllLocations] = useState([]);
    const [mapFlyToLocation, setMapFlyToLocation] = useState(null);

    //Only want to get all parking locations during intial render.
    useEffect(() => {
        getAllParkingLocations();
     //eslint-disable-next-line
    },[])

    useEffect(() => {
        getParkingLocations();
        getGroupedParkingLocations();
    //eslint-disable-next-line
    },[apiURL, db, user, allLocations])

    useEffect(() => {
        if(selectedLocationsList.length > 0) {
            // Track ungrouped location pkids for use in grouped_parking_locs location_ids array
            let idList = selectedLocationsList.map((loc) => {return loc.pkid});
            // Track UUIDs for tracking selected locations list in the tool
            let uuidList = selectedLocationsList.map((loc) => {return loc.uuid});
            setSelectedLocationsIds(idList);
            setSelectedLocationsUuids(uuidList);
        } else {
            setSelectedLocationsIds([]);
            setSelectedLocationsUuids([]);
        }
    },[selectedLocationsList])

    function runAnalytics() {
        const url = apiURL + `runAnalytics?dbName=${db}`;
        // this only re-runs regular ezEV, not sensitivity
        const obj = { company_ident: props.db, baseline: true, isTD: props.dbSettings.is_td };
        try {
          fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
            body: JSON.stringify(obj),
          }).then((data) => {
            if (data.status === 200) {
              window.alert("Location results updated.");
            } else {
              window.alert(SERVER_ERRORED_MESSAGE);
            }
          });
        } catch (err) {
          console.error(err);
        }
      }

      function handleRefresh() {
        const url = `${apiURL}isAnalyticsProcessing?dbName=${db}`;
        fetch(url, {
          headers: {Authorization: `Bearer ${user.token}`}
        }).then((resp) => resp.json())
          .then((data) => {
            let processing = data.data[0].analytics_processing;
            if(!processing){
              runAnalytics();
              window.alert("Updating Analytics.");
              return;
            }
            else{
              window.alert(ANALYTICS_IN_PROCESS_MESSAGE);
            }
          })
          .catch((err) => {
            window.alert(SERVER_ERRORED_MESSAGE);
            return;
          });
      }

    function updateLocationData (data) {
        let locationData = {parkingLocation: data, dbName: db};
        let url = `${apiURL}updateParkingLocation`;
        fetch(url, {
            method: 'post',
            body: JSON.stringify(locationData),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        }).then((resp) => {
            if(resp.status === 200) {
                window.alert('Location updated.');
                setShowUpdateLocationModal(false);
                getParkingLocations();
            } else {
                window.alert('There was an issue with updating the location.')
            }
        })
    }

    function getParkingLocations(){
        const controller = new AbortController();
        const signal = controller.signal;
        fetch(`${apiURL}getAllUngroupedParkingLocations?dbName=${db}`, {signal, headers: { Authorization: `Bearer ${user.token}`}})
            .then((resp) => resp.json())
            .then((data) => {
            setUngroupedLocations(data.data);
        })
    }

    function getAllParkingLocations() {
    const controller = new AbortController();
    const signal = controller.signal;
    fetch(`${apiURL}getAllParkingLocations?dbName=${db}`, {signal, headers: { Authorization: `Bearer ${user.token}`}})
        .then((resp) => resp.json())
        .then((data) => {
        setAllLocations(data.data);
    })
}

    function getGroupedParkingLocations(){
        const controller = new AbortController();
        const signal = controller.signal;
        fetch(`${apiURL}getAllGroupedParkingLocations?dbName=${db}`, {signal, headers: { Authorization: `Bearer ${user.token}`}})
            .then((resp) => resp.json())
            .then((data) => {
            if(data.status === 'success') {
                data.data.forEach((loc) => {
                    //Filter allLocations to find location details for locations included in the group. Needed for updateLocationModal/Map.
                    loc.location_details = allLocations.filter((item) => {
                        return loc.location_ids?.includes(item.pkid)
                    })
                })
                setGroupLocations(data.data);
            }
        })
    }

    function createNewGroupLocation(data) {
        let locationData = {groupedLocation: data, dbName: db};
        let url = `${apiURL}createGroupedParkingLocation`;
        fetch(url, {
            method: 'post',
            body: JSON.stringify(locationData),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        }).then((resp) => {
            if(resp.status === 200) {
                window.alert('Group created. Be sure to run analytics to see updated results in products.')
                setShowCreateGroupLocationModal(false);
                getParkingLocations();
                getGroupedParkingLocations();
                setSelectedLocationsList([]);
            } else {
                window.alert('Error creating group')
            }
        })
    }

    function updateGroupLocation(data) {
        let locationData = {groupedLocation: data, dbName: db};
        let url = `${apiURL}updateGroupedParkingLocation`;
        fetch(url, {
            method: 'post',
            body: JSON.stringify(locationData),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        }).then((resp) => {
            if(resp.status === 200) {
                window.alert('Group updated. Be sure to run analytics to see updated results in products.')
                setShowUpdateLocationModal(false);
                setSelectedLocationsList([]);
                setSelectedGroupLocation([]);
                getParkingLocations();
                getGroupedParkingLocations();
            } else {
                window.alert('Error updating group')
            }
        })
    }

    function addLocationsToGroupedLocation() {
        let newGroupLocationList = selectedGroupLocation.location_ids.concat(selectedLocationsIds);
        let data = {
            address: selectedGroupLocation.address,
            city: selectedGroupLocation.city,
            region: selectedGroupLocation.region,
            longitude: selectedGroupLocation.longitude,
            latitude: selectedGroupLocation.latitude,
            nickname: selectedGroupLocation.nickname,
            landuse: selectedGroupLocation.landuse,
            location_ids: newGroupLocationList,
            pkid: selectedGroupLocation.pkid,
            uuid: selectedGroupLocation.uuid
        }
        let locationData = {groupedLocation: data, dbName: db};
        let url = `${apiURL}updateGroupedParkingLocation`;
        fetch(url, {
            method: 'post',
            body: JSON.stringify(locationData),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        }).then((resp) => {
            if(resp.status === 200) {
                window.alert('Group Updated');
                getParkingLocations();
                getGroupedParkingLocations();
                setSelectedLocationsList([]);
                setSelectedGroupLocation([]);
            } else {
                window.alert('Error creating group')
            }
        })
    }

    function deleteGroupLocation(loc) {
        if(window.confirm(`Are you sure you want to delete group: ${loc.address}?`)) {
            let locationData = {groupedLocationId: loc.pkid, dbName: db};
            let url = `${apiURL}deleteGroupedParkingLocation`;
            fetch(url, {
                method: 'DELETE',
                body: JSON.stringify(locationData),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            }).then((resp) => {
            if(resp.status === 200) {
                window.alert('Group Deleted')
                getParkingLocations();
                getGroupedParkingLocations();
                setSelectedGroupLocation([]);
            } else {
                window.alert('Error deleting group')
            }
        })
        }
    }


    function SearchColumnFilter({ column: { filterValue, setFilter } }) {
        return (
          <S.AdminTableColumnSearchBar
            value={filterValue || ""}
            onChange={(e) => {
              setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Search`}
          />
        );
      }

    const BoolTableCell = useCallback((props) => 
      <input
        className='swt-admin-table-input'
        type="checkbox"
        accessor={props.accessor} 
        style={{color: props.bool ? "black" : "transparent"}} 
        checked={(props.bool === null || typeof props.bool === "undefined") ? false : props.bool} 
        disabled={props.disabled ? true : false}
        email={props.email}
        onChange={props.handleOnClick}
        value={props.value}
      />,[]);

      function handleClick(e, loc) {
        if (loc.is_group === 'true') {
            return updateSelectedGroupLocation(loc);
        } else {
           return updateSelectedLocations(e.target.value);
        }
      }

     function updateSelectedGroupLocation (loc) {
        if(selectedGroupLocation?.uuid === loc.uuid) {
            return setSelectedGroupLocation([])
        } else {
            setSelectedGroupLocation(loc);
            setMapFlyToLocation(loc);
        }
     } 

    function updateSelectedLocations(newId){
        let location = ungroupedLocations.filter((l) => l.uuid === newId);
        let idx = selectedLocationsUuids.indexOf(newId);
        if(idx === -1) {
            setSelectedLocationsList([...selectedLocationsList, ...location]);
            return setMapFlyToLocation(location[0]);
        } else {
            let locationList = selectedLocationsList.filter((loc) => loc.uuid !== newId);
            return setSelectedLocationsList(locationList)
        }
      }
      function handleUpdateLocationClick (data) {
        setLocationToUpdate(data);
        setShowUpdateLocationModal(true);
      }

      function handleShowOnMapClick(loc) {
        setMapFlyToLocation(loc);
      }

    const mappedLocationData = useMemo(() => {
        if(!ungroupedLocations) return null;
        let newList = ungroupedLocations.concat(groupLocations)
        const immutableLocations = JSON.parse(JSON.stringify(newList));
        return immutableLocations.map((l) => {
            l.selected = <BoolTableCell handleOnClick={(e) => handleClick(e, l)} value={l.uuid} accessor={"selected"} bool={selectedLocationsUuids.includes(l.uuid) || selectedGroupLocation.uuid === l.uuid} />;
            l.coords = `${l.latitude}, ${l.longitude}`;
            l.is_group = (l.location_ids || l.location_ids === null) ? 'true' : 'false';
            l.total_locations = l.location_ids ? l.location_ids.length : '-';
            l.showOnMap = <S.InnerTableButton color={'green'} onClick={() => handleShowOnMapClick(l)}>Show</S.InnerTableButton>
            l.edit = <S.InnerTableButton onClick={() => handleUpdateLocationClick(l)}>Edit</S.InnerTableButton>
            l.delete = l.is_group === 'true' ? <S.InnerTableButton color={'#e01f1f'} onClick={() => deleteGroupLocation(l)}>Delete</S.InnerTableButton>: '-';
            return l
        })
    //eslint-disable-next-line
    },[ungroupedLocations, groupLocations, selectedLocationsIds, selectedGroupLocation])

    const tableColumns = [
        {Header: 'Selected', accessor: 'selected', width: 50, sortType: _boolSort},
        {Header: 'Location ID', accessor: 'uuid', Filter: SearchColumnFilter, width: 225},
        {Header: 'Address', accessor: 'address', Filter: SearchColumnFilter, width: 300},
        {Header: 'Nickname', accessor: 'nickname', Filter: SearchColumnFilter, width: 200},
        {Header: 'Total Locations', accessor: 'total_locations', width: 100},
        {Header: 'Lat/Lon', accessor: 'coords', width: 150},
        {Header: 'Show On Map', accessor: 'showOnMap', width: 110},
        {Header: 'Edit Location', accessor: 'edit', width: 110},
        {Header: 'Delete Group', accessor: 'delete', width: 110}
    ]
    return(
        <S.AdminContainer>
            {showUpdateLocationModal &&
            <UpdateLocationModal
                setShowUpdateLocationModal={setShowUpdateLocationModal}
                apiURL={apiURL}
                db={db}
                user={user}
                locationToUpdate={locationToUpdate}
                updateLocationData={updateLocationData}
                updateGroupLocation={updateGroupLocation}
            />
            }
            {showCreateGroupLocationModal &&
            <CreateLocationGroupModal
                user={user}
                apiURL={apiURL}
                db={db}
                setShowCreateGroupLocationModal={setShowCreateGroupLocationModal}
                selectedLocations={selectedLocationsList}
                selectedLocationsIds={selectedLocationsIds}
                createNewGroupLocation={createNewGroupLocation}
            />
            }
            <h2 className="swt-admin-console-ttl">Location Edit Tool</h2>
            {ungroupedLocations.length === 0 && <Loading />}
            {ungroupedLocations.length > 0 &&
            <Suspense fallback={<div></div>}>
            <S.LocationEditContainer>
            <LocationEditMap
                {...props}
                setShowUpdateLocationModal={setShowUpdateLocationModal}
                showUpdateLocationModal={showUpdateLocationModal}
                setLocationToUpdate={setLocationToUpdate}
                ungroupedLocations={ungroupedLocations}
                groupLocations={groupLocations}
                selectedGroupLocation={selectedGroupLocation}
                handleClick={handleClick}
                selectedLocationsUuids={selectedLocationsUuids}
                updateSelectedLocations={updateSelectedLocations}
                mapCenter={_getMapCenter(ungroupedLocations.concat(groupLocations))}
                mapFlyTo={mapFlyToLocation}
                setMapFlyToLocation={setMapFlyToLocation}
                selectedLocationsList={selectedLocationsList}
                setErrorList={setErrorList}
                updateSelectedGroupLocation={updateSelectedGroupLocation}
            />

            <div style={{marginTop: '20px'}}>
                <S.LocationEditBtnWrapper>
                    <S.CtaButton disabled={selectedLocationsList.length <= 1 || selectedGroupLocation.length !== 0 || errorList.length > 0} onClick={() => setShowCreateGroupLocationModal(true)}>Create Group</S.CtaButton>
                    <S.CtaButton disabled={selectedGroupLocation.length === 0 || errorList.length > 0 || selectedLocationsList.length === 0} onClick={() => addLocationsToGroupedLocation()}>Add Locations to Group</S.CtaButton>
                    <S.CtaButton onClick={() => handleRefresh()}>Run Analytics</S.CtaButton>
                </S.LocationEditBtnWrapper>
                <div style={{marginBottom: '5px'}}>
                    <S.AdminTableExplainTextSub>Locations need to be within 300 meters to be grouped together.</S.AdminTableExplainTextSub>
                    <S.AdminTableExplainTextSub>When creating a new group location, the first selected location will display a 300 meter radius circle. All additional selections must be within this area.</S.AdminTableExplainTextSub>
                    <S.AdminTableExplainTextSub>To add locations to a group, select a group along with the locations and then click Add Locations to Group button. If button is disabled then you do not have a group selected.</S.AdminTableExplainTextSub>
                    <S.AdminTableExplainTextSub>Creating or updating group locations will require analytics to be run in order for results to be updated in products.</S.AdminTableExplainTextSub>
                </div>
                {errorList.length > 0 &&
                    <div style={{color: 'red'}}>
                        The following locations can't be grouped because they are more than 300 meters apart: {errorList.map((item) => {return <div key={item}>{item}</div>})}
                    </div>
                }
                <AdminTableView 
                    columns={tableColumns} 
                    data={mappedLocationData} 
                    skipPageResetRef={true}
                    sortBy={{id: 'selected', desc: true}}
                    minWidth={'2000px'}
                />
            </div>
        </S.LocationEditContainer>
        </Suspense>
        }
        </S.AdminContainer>
    )
}

const _getMapCenter = (data) => {
    if (!data || data.length < 1) return [0, 0];
    let lat = 0;
    let lon = 0;
    let ct = 0;
    data.forEach((d) => {
        lat += d.latitude;
        lon += d.longitude;
        ct++;
    });
    return [lon/ct, lat/ct];
  }