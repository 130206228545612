import React, {useMemo} from 'react';
import { Link } from 'react-router-dom';
import { FormatSavings, FormatCommas } from './FormatAttributes';
import descArrow from '../../images/ic-arrow-desc-green-30px-19px.png';
import ascArrow from '../../images/ic-arrow-asc-red-30px-17px.png';


function AssetIdColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <input
      className="swt-ezev-td-search-input"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined)// Set undefined to remove the filter entirely
      }}
      placeholder={`Search`}
    />
  )
}

function FormatUpfits(upfits) {
  let upfitsString = "";
  if (upfits.length > 0) {
    const upfitNames = upfits.map((u) => { return u.name });
    upfitsString = upfitNames.join(', ');
  }
  return upfitsString;
}

function FormatGroupList(groups) {
  let groupsString = "";
  if (groups?.length > 0) {
    groupsString = groups.join(', ');
  }
  return groupsString;
}

export function parkingText(input) {
  if (input === null || input === undefined) return;
  if (input > 5) {
    return "top 5";
  } else if (input === 5) {
    return "5";
  } else if (input === 4) {
    return "4";
  } else if (input === 3) {
    return "3";
  } else if (input === 2) {
    return "2";
  } else if (input === 1) {
    return "1";
  } else if (input === 0) {
    return "none";
  }
}

export function FormatCell(accessor, value, rowValues, userSettings) {
  var prices = ['net_price', 'price'];
  var numbersWithCommas = ['weight']
  var round = ['obs_avg_dwell'];
  var groupList = ['group_memberships'];
  // including all scores for good measure. Overall is the only one that may not be an integer currently
  var roundScore = ['overall', 'economics', 'confidence', 'energy', 'parking']
  var roundPercent = ['rec_tco_reduction_pct', 'rec_lt_ghg_reduction_pct', 'parking_loc_pct'];
  var toTen = ['rec_lt_ghg_reduction_lbs', 'rec_lt_gals_saved', 'est_yearly_km'];
  var toSavingStr = ['rec_lt_net_savings', 'rec_lt_ops_savings', 'rec_lt_net_savings_range', 'rec_lt_ops_savings_range'];
  var toOneDecimal = ['avg_daily_kwh', 'rec_ev_avg_level1_hrs', 'rec_ev_avg_level2_hrs', 'rec_ev_avg_dc_hrs', 'avg_daily_idling_hrs', 'battery_capacity'];
  var upfits = ['required_upfits', 'optional_upfits', 'upfits'];
  if (round.includes(accessor)) {
    value = Math.round(value);
    // if (value === 0) value = "<1";
  } else if (roundScore.includes(accessor)) {
    value = Math.floor(value);
    if (accessor === 'energy' && value === -1) {
      value = "Not Applicable";
    }
  } else if (roundPercent.includes(accessor)) {
    value = (value) ? `${Math.round(value)}%` : "-";
  } else if (toTen.includes(accessor)) {
    value = Math.round(value / 10) * 10;
    // value = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // add commas
    value = FormatCommas(value, userSettings);
  } else if (accessor === 'energy') {
    if (value === -1) value = "Not Applicable";
    else value = Math.round(value);
  } else if (accessor === 'rec_midday_charge_str') {
    value = (value === "NA")  ? "Not Applicable" : value;
  } else if (toSavingStr.includes(accessor)) {
    value = FormatSavings(value, userSettings);
  } else if (prices.includes(accessor)) {
    value = (value !== null && value !== undefined) ? `${userSettings.currency_symbol}${FormatCommas(value, userSettings)}` : "-";
  } else if (toOneDecimal.includes(accessor)) {
    value = Math.ceil(value*10)/10;
  } else if (numbersWithCommas.includes(accessor)) {
    value = FormatCommas(value, userSettings);
  } else if (upfits.includes(accessor)) {
    value = FormatUpfits(value);
  } else if (groupList.includes(accessor)) {
    value = FormatGroupList(value);
  }
  if(!value || value === 'null' || value === 'VIN Decoding Error') {
    value = '-';  
  }
  return value;
}

export default function GetColumns(userSettings) {

  const tcoSort = useMemo(() => (rowA, rowB) => {
    const a = rowA.values.rec_lt_net_savings
    const b = rowB.values.rec_lt_net_savings
    if(rowA.values.rec_lt_net_savings === '-' && rowB.values.rec_lt_net_savings !== '-'){
      return -1;
    } else if(rowB.values.rec_lt_net_savings === '-' && rowA.values.rec_lt_net_savings !== '-') {
      return 1;
    } else {
      return a - b;
    }
  },[]);

  const numericSort = useMemo(() => (rowA,rowB, colId) => {
    const a = rowA.values[colId];
    const b = rowB.values[colId];
    //This sorts so that dashes in the table are ranked as lowest possible value
    if(!rowA.values[colId] && rowB.values[colId]){
      return -1;
    } else if (rowA.values[colId] && !rowB.values[colId]) {
      return 1;
    } else {
      return a - b;
    }
  },[])

  const overallScoreSort = useMemo(() => (rowA,rowB, colId) => {
    // for the overall score column, we want to sort by rounded values so that the secondary sort (on asset ID alphanumeric) works correctly
    const a = Math.floor(rowA.values[colId]);
    const b = Math.floor(rowB.values[colId]);
    //This sorts so that dashes in the table are ranked as lowest possible value
    if(!rowA.values[colId] && rowB.values[colId]){
      return -1;
    } else if (rowA.values[colId] && !rowB.values[colId]) {
      return 1;
    } else {
      return a - b;
    }
  },[])

  const stringSort = useMemo(() => (rowA, rowB) => {
    let a = rowA.values.asset_id.toLowerCase();
    let b = rowB.values.asset_id.toLowerCase();
    return a > b ? 1 : -1;
  },[])

  // return 'everything' table
  const formattedCell = (obj) => {
    var column = obj.column.id;
    var value = obj.value;
    const rowValues = obj.row.original
    var formattedValue = FormatCell(column, value, rowValues, userSettings);
    if (formattedValue === null || formattedValue === undefined) return '';
    var className = "ezev-table-cell-string ";
    if (typeof formattedValue == 'number') className = "ezev-table-cell-num ";
    if (column === 'asset_id') className += 'asset_id  ';
    if (column === 'overall') className += 'overall';
    if (column === 'rec_lt_net_savings' || column === 'rec_lt_ops_savings' || column === 'rec_tco_reduction_pct') {
      return <div className={className}>
        <Link to={{ pathname: `/ezev-td/vehicle/${obj.row.original.vin}`, state: { vcl: obj.row.original.vin } }} >
          {(formattedValue !== "Cost Parity" && formattedValue !== "0%" && formattedValue !== '-') &&
            <img className={`ezev-change-arrow ${Math.round(value) > 0 ? "asc" : "desc"}`} src={Math.round(value) > 0 ? ascArrow : descArrow} alt="Cost difference arrow" />
          }
          {(formattedValue === "Cost Parity" || formattedValue === "0%") &&
            <span className="ezev-no-change-arrow"></span>
          }
          {formattedValue}
        </Link>
      </div>;
    } else {
      return <div className={className}>
        <Link to={{ pathname: `/ezev-td/vehicle/${obj.row.original.vin}`, state: { vcl: obj.row.original.vin } }} >{formattedValue}</Link>
      </div>;
    }
  };
  return [
    {
      Header: 'Asset ID',
      accessor: 'asset_id',
      testid: 'asset_id',
      width: 200,
      Cell: formattedCell,
      canFilter: true,
      Filter: AssetIdColumnFilter,
      sortType: stringSort
    },
    {
      Header: 'Year',
      accessor: 'year',
      testid: 'year',
      Cell: formattedCell,
      width: 100,
    },
    {
      Header: 'Make',
      accessor: 'make',
      testid: 'make',
      width: 150,
      Cell: formattedCell
    },
    {
      Header: 'Model',
      accessor: 'model',
      testid: 'model',
      width: 150,
      Cell: formattedCell
    },
    {
      Header: () => <div className="ezev-table-header-long">Recommendation<sup>*</sup></div>,
      accessor: 'recommendation',
      testid: 'recommendation',
      width: 200,
      Cell: formattedCell
    },
    {
      Header: 'Overall Score',
      accessor: 'overall',
      className: "overall",
      testid: 'overall_score',
      Cell: formattedCell,
      width: 100,
      sortType: overallScoreSort
    },
    {
      Header: () => <div className="ezev-table-header-long" data-testid="yearly_distance-header-testid">Annual <br />{`Est. ${userSettings.use_kms ? 'VKMT' : 'VMT'}`}</div>,
      accessor: 'est_yearly_km',
      testid: 'yearly_distance',
      Cell: formattedCell,
      width: 100,
      sortType: numericSort
    },
    {
      Header: () => <div className="ezev-table-header-long">Economics<br />Score</div>,
      accessor: 'economics',
      testid: 'economics_score',
      width: 150,
      Cell: formattedCell,
      sortType: numericSort
    },
    {
      Header: () => <div className="ezev-table-header-long">TCO<br />(Lifetime)</div>,
      accessor: 'rec_lt_net_savings',
      testid: 'lifetime_savings',
      width: 150,
      sortType: tcoSort,
      Cell: formattedCell,
    },
    {
      Header: () => <div className="ezev-table-header-long">Energy<br />Score</div>,
      accessor: 'energy',
      testid: 'energy_score',
      Cell: formattedCell,
      width: 100,
      sortType: numericSort
    },
    {
      Header: () => <div className="ezev-table-header-long">Midday<br />Charging Needs</div>,
      accessor: 'rec_midday_charge_str',
      testid: 'midday_charging',
      width: 150,
      sortMethod: (a, b) => {
        if (a === b) { return 0; }
        var aValue = 6;
        if (a === "N/A") { aValue = 0 }
        else if (a === "Not needed") { aValue = 1 }
        else if (a === "Approx once a month") { aValue = 2 }
        else if (a === "Several times per month") { aValue = 3 }
        else if (a === "Approx once per week") { aValue = 4 }
        else if (a === "Several times per week") { aValue = 5 }
        else if (a === "Very often") { aValue = 6 }
        var bValue = 6;
        if (b === "N/A") { bValue = 0 }
        else if (b === "Not needed") { bValue = 1 }
        else if (b === "Approx once a month") { bValue = 2 }
        else if (b === "Several times per month") { bValue = 3 }
        else if (b === "Approx once per week") { bValue = 4 }
        else if (b === "Several times per week") { bValue = 5 }
        else if (b === "Very often") { bValue = 6 }
        return aValue > bValue ? 1 : -1;
      },
      Cell: formattedCell
    },
    {
      Header: () => <div className="ezev-table-header-long">Parking<br />Score</div>,
      accessor: 'parking',
      testid: 'parking_score',
      Cell: formattedCell,
      width: 90,
      sortType: numericSort
    },
    {
      Header: () => <div className="ezev-table-header-long">Confidence<br />Score</div>,
      accessor: 'confidence',
      testid: 'confidence_score',
      Cell: formattedCell,
      width: 110,
      sortType: numericSort
    },
    {
      Header: () => <div className="ezev-table-header-long">Reason for<br />Failure</div>,
      accessor: 'failure_reason',
      testid: 'failure_reason',
      Cell: formattedCell,
      width: 250
    },
    {
      Header: () => <div data-testid="ghg_reduction_units-header-testid" className="ezev-table-header-long">GHG Reduction <br />({userSettings.weight_labels.shortPlural})</div>,
      accessor: 'rec_lt_ghg_reduction_lbs',
      testid: 'ghg_reduction_units',
      width: 150,
      Cell: formattedCell,
      sortType: numericSort
    },
    {
      Header: () => <div className="ezev-table-header-long">GHG Reduction<br />(%)</div>,
      accessor: 'rec_lt_ghg_reduction_pct',
      testid: 'ghg_reduction_percentage',
      width: 150,
      sortType: numericSort,
      Cell: formattedCell
    },
    {
      Header: () => <div className="ezev-table-header-long">Special Vehicle<br />Configuration</div>,
      accessor: 'upfits_string',
      testid: 'upfits_string',
      width: 175,
      Cell: formattedCell
    },
    {
      Header: 'Fuel Type',
      accessor: 'fuel_type',
      testid: 'fuel_type',
      width: 150,
      Cell: formattedCell
    },

    //Give every item an empty filter unless they have one defiend
  ].map(item => ({ Filter: <div />, ...item }));



}

