import { DateTime } from "luxon";

const KMS_TO_MILES = 0.621371;
const LITERS_TO_GALS = 0.264172;
const GALS_TO_LITERS = 3.78541;
const LBS_TO_KGS = 0.453592;
const GMS_TO_LBS = 0.00220462;

// These values are correct - converting to per liter and per gallon respectively
const PER_GALLON_TO_PER_LITER = 0.264172;
const PER_LITER_TO_PER_GALLON = 3.78541;

const shortMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
]

function trimTzDesignation (date) {
    if(typeof date === 'string')return date.replace("Z", "");
    else return date;
}

export function processRateTime(settings, rateObj) {
    switch(settings.ts_display) {
        case "hh:mm:ss xm":
            rateObj.startTimestamp = formatRateStandardTimeSeconds('start', rateObj);
            rateObj.stopTimestamp = formatRateStandardTimeSeconds('stop', rateObj);
            break;
        case "hh:mm:ss":
            rateObj.startTimestamp = formatRateMilitaryTimeSeconds('start', rateObj);
            rateObj.stopTimestamp = formatRateMilitaryTimeSeconds('stop', rateObj);
            break;
        case "hh:mm xm":
            rateObj.startTimestamp = formatRateStandardTime('start', rateObj);
            rateObj.stopTimestamp = formatRateStandardTime('stop', rateObj);
            break;
        case "hh:mm":
            rateObj.startTimestamp = formatRateMilitaryTime('start', rateObj);
            rateObj.stopTimestamp = formatRateMilitaryTime('stop', rateObj);
            break;
        default:
            rateObj.startTimestamp = formatRateMilitaryTimeSeconds('start', rateObj);
            rateObj.stopTimestamp = formatRateMilitaryTimeSeconds('stop', rateObj);
    }
    return rateObj;
}

export function processApiResponse(userSettings, resp) {
    if(!resp)return;
    const dollarValues = ['0-30', '31-60', '61-90', '91-120', '121-150', '151-180', 'cost',
                          'fuelCostSavings', 'fuel_cost', 'totalCost', 'opCost', 'opChangeToDate',
                          'opChangeLt', 'tcoChangeLt', 'fuelCost', 'netPriceCandidate', 'netPriceVehicle',
                          'avgSixPrior', 'lastMonth', 'twoPrior',
                          'bev_purchase_price', 'phev_purchase_price', 'ice_purchase_price', 'kwhRate', 'local_kwh_cost'];
    const dates = ['utc_start', 'utc_stop', 'utcStart', 'utcEnd', 'localized_start', 'localized_stop', 'vehicle_moved', 'vehicle_moved_local', 'start', 'end', 'minDate', 'localized_date', 'min', 'max']
    const utcDates = ['utc_start', 'utc_stop', 'utcStart', 'utcEnd', 'vehicle_moved', 'minDate'];
    const localDates = ['localized_start', 'localized_stop', 'vehicle_moved_local', 'localized_date'];
    const liters = ['fuelSavings', 'mo_fu_liters', 'fuLitersActual', 'fuLiters'];
    const distancePerLiter = ['kms_per_liter'];
    // NOTE does not cover mpg_c, mpg_h (not currently used in this repo)
    Object.keys(resp).forEach((k) => {
        if(k === 'fuel_cost') {
            let fuel_cost_liters = formatPerGal(userSettings, resp[k])
            resp[k] = fuel_cost_liters
        }
        if(k === 'ghgRedGms' | k === 'ghgGms') {
            let ghg = formatGhg(userSettings, resp[k])
            resp[k] = ghg
        }
        if(k.includes('km')){
            // deal with formatting for elec_km (mi/kwh precursor) independently
            if(k === 'elec_km'){
                let effic = formatElecFuelEfficiency(userSettings, resp[k])
                resp[k] = effic
            }
            else {
                let dist = formatDistance(userSettings, resp[k])
                resp[k] = dist
            }
        }
        if(dates.includes(k)){
            if (localDates.includes(k)) { // Only trim for "local" dates. Want to preserve conversion of UTC dates
                resp[k] = trimTzDesignation(resp[k])
            }
            let ts = DateTime.utc();
            if(typeof resp[k] === 'string' && utcDates.includes(k))ts = formatTimestamp(userSettings, resp[k] ? DateTime.fromISO(resp[k]).toUTC() : null); // Control UTC using DateTime
            else if(typeof resp[k] === 'string')ts = formatTimestamp(userSettings, resp[k] ? new Date(resp[k]) : null); // Anything without UTC has already been controlled for local evt time
            else if(resp[k] instanceof Date || resp[k] instanceof DateTime)ts = formatTimestamp(userSettings, resp[k]); // Case where Date/DateTime are provided instead of string
            resp[k] = ts
        }
        if(liters.includes(k)){
            let lq = formatLtrs(userSettings, resp[k])
            resp[k] = lq
        }
        if(distancePerLiter.includes(k)) {
            let lq = formatPerLiter(userSettings, resp[k]);
            resp[k] = lq;
        }
        if(k.includes('gal')){
            let lq = formatGals(userSettings, resp[k])
            resp[k] = lq
        }
        if(dollarValues.includes(k)){
            let cost = formatCurrency(userSettings, resp[k])
            resp[k] = cost
        }
        if(k.includes('lbs')){
            let weight = formatLbs(userSettings, resp[k])
            resp[k] = weight
        }
    })
    return resp;
  }

function formatElecFuelEfficiency(settings, value){
    if(!value)return value;
    if(settings.elec_fuel_economy === 'km/kWh'){
        return value;
    }
    return kmsToMiles(value)
}

function formatDistance(settings, value){
    if(!value)return value;
    if(settings.use_kms)return value;
    return kmsToMiles(value)
}

export function formatTimestamp(settings, value){
    const date = formatDate(settings, value);
    const time = formatTime(settings, value);
    const ts = getTs(value);
    return {"date": date, "time": time, "ts": ts};
}

function getTs(value) {
    if(value instanceof Date) return value.getTime();
    if(value instanceof DateTime) return value.ts;
}

function formatDate(settings, value) {
    if (!value) return value;
    switch(settings.date_display){
        case 'dd/MM/yy':
            if(value instanceof Date)return `${('0' + value.getDate()).slice(-2)} ${(shortMonths[value.getMonth()])} ${value.getFullYear().toString().substring(2, 4)}`;
            if(value instanceof DateTime)return `${('0' + value.day).slice(-2)} ${(shortMonths[value.month-1])} ${value.year.toString().substring(2, 4)}`;
            return null;
        case 'dd/MM/yyyy':
            if(value instanceof Date)return `${('0' + value.getDate()).slice(-2)} ${(shortMonths[value.getMonth()])} ${value.getFullYear()}`;
            if(value instanceof DateTime)return `${('0' + value.day).slice(-2)} ${(shortMonths[value.month-1])} ${value.year}`;
            return null;
        case 'MM/dd/yy':
            if(value instanceof Date)return `${(shortMonths[value.getMonth()])} ${('0' + value.getDate()).slice(-2)} ${value.getFullYear().toString().substring(2, 4)}`;
            if(value instanceof DateTime)return `${(shortMonths[value.month-1])} ${('0' + value.day).slice(-2)} ${value.year.toString().substring(2, 4)}`;
            return null;
        case 'MM/dd/yyyy':
            if(value instanceof Date)return `${(shortMonths[value.getMonth()])} ${('0' + value.getDate()).slice(-2)} ${value.getFullYear()}`;
            if(value instanceof DateTime)return `${(shortMonths[value.month-1])} ${('0' + value.day).slice(-2)} ${value.year}`;
            return null;
        case 'yy/MM/dd':
            if(value instanceof Date)return `${value.getFullYear().toString().substring(2, 4)} ${shortMonths[value.getMonth()]} ${('0' + value.getDate()).slice(-2)}`;
            if(value instanceof DateTime)return `${value.year.toString().substring(2, 4)} ${shortMonths[value.month-1]} ${('0' + value.day).slice(-2)}`;
            return null;
        case 'yyyy/MM/dd':
            if(value instanceof Date)return `${value.getFullYear()} ${shortMonths[value.getMonth()]} ${('0' + value.getDate()).slice(-2)}`;
            if(value instanceof DateTime)return `${value.year} ${shortMonths[value.month-1]} ${('0' + value.day).slice(-2)}`;
            return null;
        default:
            if(value instanceof Date)return `${shortMonths[value.getMonth()]} ${('0' + value.getDate()).slice(-2)} ${value.getFullYear().toString().substring(2, 4)}`;
            if(value instanceof DateTime)return `${shortMonths[value.month-1]} ${('0' + value.day).slice(-2)} ${value.year.toString().substring(2, 4)}`;
            return null;
    }
}

function formatTime(settings, value) {
    if (!value) return value;
    switch(settings.ts_display){
        case "hh:mm:ss xm":
            if(value instanceof Date)return `${addLeadingZero(militaryToStandardHours(value.getHours()))}:${addLeadingZero(value.getMinutes())}:${addLeadingZero(value.getSeconds())} ${amPm(value.getHours())}`;
            if(value instanceof DateTime)return `${addLeadingZero(militaryToStandardHours(value.hour))}:${addLeadingZero(value.minute)}:${addLeadingZero(value.second)} ${amPm(value.hour)}`;
            return null;
        case "hh:mm:ss":
            if(value instanceof Date)return `${addLeadingZero(value.getHours())}:${addLeadingZero(value.getMinutes())}:${addLeadingZero(value.getSeconds())}`;
            if(value instanceof DateTime)return `${addLeadingZero(value.hour)}:${addLeadingZero(value.minute)}:${addLeadingZero(value.second)}`;
            return null;
        case "hh:mm xm":
            if(value instanceof Date)return `${addLeadingZero(militaryToStandardHours(value.getHours()))}:${addLeadingZero(value.getMinutes())} ${amPm(value.getHours())}`; 
            if(value instanceof DateTime)return `${addLeadingZero(militaryToStandardHours(value.hour))}:${addLeadingZero(value.minute)} ${amPm(value.hour)}`;
            return null;
        case "hh:mm":
            if(value instanceof Date)return `${addLeadingZero(value.getHours())}:${addLeadingZero(value.getMinutes())}`;
            if(value instanceof DateTime)return `${addLeadingZero(value.hour)}:${addLeadingZero(value.minute)}`;
            return null;
        default:
            if(value instanceof Date)return `${addLeadingZero(value.getHours())}:${addLeadingZero(value.getMinutes())}:${addLeadingZero(value.getSeconds())}`;
            if(value instanceof DateTime)return `${addLeadingZero(value.hour)}:${addLeadingZero(value.minute)}:${addLeadingZero(value.second)}`;
            return null;
    }
}

function amPm(hour) {
    return hour > 11 && hour < 24 ? 'pm' : 'am'
}

function addLeadingZero(value) {
    if (value > 9) return value;
    else return `0${value}`;
}

function militaryToStandardHours(value) {
    if (value === 0)
        return 12;
    if (value > 12) {
        return value - 12;
    }
    return value;
}

function formatLtrs(settings, value){
    if(!value)return value;
    if(settings.use_liters)return value;
    // use liquid_fuel_economy to inform if want gals / liters?
    // add settings for just liquid units?
    return ltrsToGals(value)
}
function formatGals(settings, value){
    if(!value)return value;
    if(!settings.use_liters)return value;
    // use liquid_fuel_economy to inform if want gals / liters?
    // add settings for just liquid units?
    return galsToLtrs(value)
}
function formatPerGal(settings, value) {
    if(!value) return value;
    if(!settings.use_liters)return value;
    return perGalToPerLiter(value);
}
function formatPerLiter(settings, value) {
    if(!value) return value;
    if(settings.use_liters) return value;
    return perLiterToPerGal(value);
}
function formatCurrency(settings, value){
    if(!value)return value;
    if(settings.db_currency === settings.currency)return value;
    // if user currency !== db currency, convert using applicable conversion rate
    let conversion_rate = 1;
    if(settings.db_currency === 'EUR' && settings.currency === 'USD'){
        conversion_rate = settings.to_usd;
    }
    if(settings.db_currency === 'USD' && settings.currency === 'EUR'){
        conversion_rate = settings.to_eur;
    }
    return (value*conversion_rate);
}
function formatLbs(settings, value){
    if(!value)return value;
    if(settings.use_kgs)return lbsToKgs(value);
    return value;
}
function kmsToMiles(value){
    return (value * KMS_TO_MILES)
}
function ltrsToGals(value){
    return (value * LITERS_TO_GALS)
}
function galsToLtrs(value){
    return (value * GALS_TO_LITERS)
}
function perGalToPerLiter(value){
    return (value * PER_GALLON_TO_PER_LITER);
}
function perLiterToPerGal(value) {
    return (value * PER_LITER_TO_PER_GALLON);
}
function lbsToKgs(value){
    return (value * LBS_TO_KGS);
}
function formatGhg(settings, value) {
    if(settings.use_kgs) return (value * GMS_TO_LBS) * LBS_TO_KGS
    return (value * GMS_TO_LBS)
}

function formatRateMilitaryTime(key, rateObj) {
    return `${addLeadingZero(rateObj[`${key}Hour`])}:${addLeadingZero(rateObj[`${key}Minute`])}`;
}

function formatRateMilitaryTimeSeconds(key, rateObj) {
    return `${addLeadingZero(rateObj[`${key}Hour`])}:${addLeadingZero(rateObj[`${key}Minute`])}:00`;
}

function formatRateStandardTime(key, rateObj) {
    return `${addLeadingZero(militaryToStandardHours(rateObj[`${key}Hour`]))}:${addLeadingZero(rateObj[`${key}Minute`])} ${amPm(rateObj[`${key}Hour`])}`;
}

function formatRateStandardTimeSeconds(key, rateObj) {
    return `${addLeadingZero(militaryToStandardHours(rateObj[`${key}Hour`]))}:${addLeadingZero(rateObj[`${key}Minute`])}:00 ${amPm(rateObj[`${key}Hour`])}`;
}
