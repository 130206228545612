import React, { useCallback } from "react"
import * as S from "../../styles/ezio-styles/Controls-styles"
import { ChangeElectrificationAction, ChangeClassAction, ChangeLocationAction, ChangeCategoryAction, ChangeGroupAction } from "../swt-ezio"
import CategoryDropdown from "./CategoryDropdown"
import GroupsDropdown from "./GroupsDropdown"
import LocationsDropdown from "./LocationsDropdown"
import ElectrificationDropdown from "./ElectrificationDropdown"
import ClassesDropdown from "./ClassesDropdown"
import { ChargeLocation } from "../swt-ezio";
import DateSelectors from "./DateSelectors";
import { DateTime } from "luxon";

//import grayArrow from "../../images/ic-refresh-gray-a100-504px-587px.png"
//import lightGreenArrow from "../../images/ic-refresh-lightgreen-a100-504px-587px.png"
//import greenArrow from "../../images/ic-refresh-green-a100-504px-587px.png"

function emptyChargeLocation(cl: ChargeLocation){
    //numbers to 0, arrays to empty and objects to undefined
    //immutable props commented out.
    //cl.pkid: number,
    //cl.address: string,
    //cl.latitude: number,
    //cl.longitude: number,
    cl.chargingVehiclesCount = 0;
    cl.inBoundChargingVehiclesCount = 0;
    cl.chargingVehiclesVins = [];
    cl.chargeDurationSeconds = 0;
    cl.chargeDayCount = 0;
    cl.charge_events = [];
    cl.peakKw= 0;
    //cl.smoothedPeakKW= 0;
    cl.peakCost= 0;
    cl.monthlyPeaks = undefined;
    cl.todSummaries = undefined;
    cl.todSmartSummaries = undefined;
    cl.todPeakSmoothedSummaries = undefined; 
    cl.evRecommendationCount = 0;
    cl.maxVehiclesCharging = 0;
    cl.chargeEventCount = 0;
    //cl.vehicleResults = []; --> Immutable after assignment
    cl.presentationEvents = [];
    //cl.vins = []; --> Immutable after assignment
    cl.summary = undefined;
    //cl.pkids = []; --> Immutable after assignment
    //cl.locationEmpty: boolean, <-- Unused?
    return cl;
}

type ControlsProps = {
    groups: Array<any>
    chargeLocations: Map<string, ChargeLocation>
    classes: Array<string>
    onChangeControls: Function
    minDate?: DateTime
    maxDate?: DateTime
    selectedBeginDate?: DateTime
    selectedEndDate?: DateTime
    handleDateChange: Function
    setApplyControl: Function
    setDatesChanged: Function
    setLoadChargeLocations: Function
    datesChanged: boolean
    setSelectedChargeLocation: Function
    tempSelectedChargeLocation: string
    tempSelectedCategory: any
    tempSelectedGroups: any
    tempSelectedVehicleClasses: Array<string>
    tempSelectedElectrification: number
    setSelectedCategory: Function
    setSelectedGroups: Function
    setSelectedVehicleClasses: Function
    setSelectedElectrification: Function
    submitButtonDisabled: boolean
    setSubmitButtonDisabled: Function
    userSettings: Object
    limitedDateRange: boolean,
    location?: string,
    ALL_LOCATIONS_ID: string
}


export default function Controls({
    groups,
    chargeLocations,
    classes,
    onChangeControls,
    minDate,
    maxDate,
    selectedBeginDate,
    selectedEndDate,
    handleDateChange,
    setApplyControl,
    setLoadChargeLocations,
    datesChanged,
    setDatesChanged,
    setSelectedChargeLocation,
    tempSelectedChargeLocation,
    tempSelectedCategory,
    tempSelectedGroups,
    tempSelectedVehicleClasses,
    tempSelectedElectrification,
    setSelectedCategory,
    setSelectedGroups,
    setSelectedVehicleClasses,
    setSelectedElectrification,
    submitButtonDisabled,
    setSubmitButtonDisabled,
    userSettings,
    limitedDateRange,
    location,
    ALL_LOCATIONS_ID}: ControlsProps) {

    const disableDateSelectors = location?.includes('kw-demand') ? true : false;
    const applyControlSet = useCallback(() => {
        // Reflect actual control state upon submission
        const arr = Array.from(chargeLocations.values());
        arr.forEach((cl: ChargeLocation) => {
            const c = emptyChargeLocation(cl);
            chargeLocations.set(cl.uuid, c); //explicity setting/not relying on the pointer for clarity
        });
        setSelectedChargeLocation(tempSelectedChargeLocation);
        setSelectedCategory(tempSelectedCategory);
        setSelectedVehicleClasses(tempSelectedVehicleClasses);
        setSelectedGroups(tempSelectedGroups);
        setSelectedElectrification(tempSelectedElectrification);
        if(datesChanged) {
            // If dates changed, we want to fire off a full re-fetch of data
            setLoadChargeLocations(true);
            setDatesChanged(false);
        }
        // Otherwise, we just want to re-apply the controls on the existing dataset
        else {
            setApplyControl(true);
        };
        setSubmitButtonDisabled(true);
    }, [chargeLocations, 
        datesChanged,
        setApplyControl,
        setDatesChanged,
        setLoadChargeLocations,
        setSelectedCategory, 
        setSelectedChargeLocation, 
        setSelectedElectrification, 
        setSelectedGroups, 
        setSelectedVehicleClasses, 
        tempSelectedCategory, 
        tempSelectedChargeLocation, 
        tempSelectedElectrification, 
        tempSelectedGroups, 
        tempSelectedVehicleClasses,
        setSubmitButtonDisabled])

    return (
        <S.ControlRow>
            <S.ControlSection>
                <S.ControlItem>
                    <LocationsDropdown chargeLocations={chargeLocations} selectedChargeLocation={tempSelectedChargeLocation} ALL_LOCATIONS_ID={ALL_LOCATIONS_ID} onChange={(action: ChangeLocationAction) => onChangeControls({ location: action })} />
                </S.ControlItem>
                <S.ControlItem>
                    <CategoryDropdown selectedCategory={tempSelectedCategory.label} onChange={(action: ChangeCategoryAction) => onChangeControls({ category: action })} />
                </S.ControlItem>
                <S.ControlItem>
                    <GroupsDropdown groups={groups} selectedGroups={tempSelectedGroups} onChange={(action: ChangeGroupAction) => onChangeControls({ groups: action })} />
                </S.ControlItem>
                <S.ControlItem>
                    <ClassesDropdown classes={classes} selectedClasses={tempSelectedVehicleClasses} onChange={(action: ChangeClassAction) => onChangeControls({ classes: action })} />
                </S.ControlItem>
            </S.ControlSection>
            <S.VerticalRule/>
            <S.ControlSection>
                <S.ControlItem>
                    <ElectrificationDropdown selectedElectrification={tempSelectedElectrification} onChange={(action: ChangeElectrificationAction) => onChangeControls({ electrification: action })} />
                </S.ControlItem>
                <S.ControlItem>
                    <DateSelectors
                        selectorPosition="exterior"
                        dateBounds={{min: minDate, max: maxDate}}
                        selectedDates={{min:selectedBeginDate, max: selectedEndDate}}
                        handleChange={handleDateChange}
                        disabled={disableDateSelectors}
                        userSettings={userSettings}
                        limitedDateRange={limitedDateRange}
                    />
                </S.ControlItem>
                <S.ControlItem>
                    <S.SubmitButton id="control-submit-button" disabled={submitButtonDisabled} onClick={() => {
                        if(!submitButtonDisabled) applyControlSet();
                    }}>Update Controls</S.SubmitButton>
                </S.ControlItem>
            </S.ControlSection>
        </S.ControlRow>
    )
}
